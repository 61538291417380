
import React, { useState, useEffect } from "react";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';



function JobDetails() {
  const [jobName, setJobName] = useState([]);
  const [jobId, setJobId] = useState([]);
  const [jobEditorText, setJobEditor] = useState([]);
  

  useEffect(() => {
    debugger
    setJobEditor(sessionStorage.getItem('jobEditorText'))
    setJobName(sessionStorage.getItem('jobName'))
    setJobId(sessionStorage.getItem('jobId'))
    // sessionStorage.removeItem('jobId');
    // sessionStorage.removeItem('jobName');
    // sessionStorage.removeItem('jobEditorText');
  }, []);
  const navigateToApplyNow = () => {
    sessionStorage.setItem('jobId', jobId);
    sessionStorage.setItem('jobName', jobName);
    sessionStorage.setItem('jobEditorText', jobEditorText);
    navigate('/apply-now');
    
  }
  const navigate = useNavigate();
  debugger
  const htmlString = sessionStorage.getItem('jobEditorText');
  const theObj = { __html: htmlString };
  console.log("theObj"+theObj);

  return (
    <>
      {/* Page Header Start */}
      <div className="container-fluid page-header5 py-5" style={{height:"340px"}}>
        <div className="container text-center py-5">
          <h2 className="display-2 text-white mb-4 animated slideInDown fs-1">
            {jobName}
          </h2>
        </div>
      </div>

      

      <div className="container-fluid py-5 mt-3">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-1">

            </div>
            <div className="col-md-10">
              {/* <div className="text-center"> */}
              <div dangerouslySetInnerHTML={theObj} />
              {/* </div> */}
            </div>
            <div className="col-md-1">
              
            </div>
         
          
          </div>
       
          {/* <button className="btn btn-primary" onClick={navigateToApplyNow}>Click Here To Apply</button> */}
          </div>
         <div className="col-md-12">
          <div className="d-flex justify-content-center">
          <button className="btn btn-primary" style={{width:"300px"}} onClick={navigateToApplyNow}>Click Here To Apply</button>
          </div>

         
         </div>
        
      </div>
    </>
  );
}

export default JobDetails;
