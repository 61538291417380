import React from 'react';
import OwlCarousel from 'react-owl-carousel-rtl';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const TestimonialCarousel = () => {
  const options = {
    loop: true,
    margin: 10,
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  return (
   <div className='row'>
    <div className='col-md-6'>
    <OwlCarousel className="owl-theme" {...options}>
     <div className=" testimonial-carousel wow fadeIn" data-wow-delay=".5s">
              <div className="testimonial-item border p-4">
                <div className="d-flex align-items-center">
                  <div className="">
                    <img src="assets/img/testimonial-2.jpg" alt="" />
                  </div>
                  <div className="ms-4">
                    <h4 className="text-secondary">MidWest Distribution</h4>
                    <p className="m-0 pb-3">Marketing Head</p>
                    <div className="d-flex pe-5">
                      <i className="fas fa-star me-1 text-primary" />
                      <i className="fas fa-star me-1 text-primary" />
                      <i className="fas fa-star me-1 text-primary" />
                      <i className="fas fa-star me-1 text-primary" />
                      <i className="fas fa-star me-1 text-primary" />
                    </div>
                  </div>
                </div>
                <div className="border-top mt-4 pt-3">
                  <p className="mb-0">
                  Working with Overseas IT Services for a long time has been an absolute pleasure 
                  for us, we are happy to have you as our off-shore service provider company.
                  </p>
                </div>
              </div>
            </div>

    </OwlCarousel>
    </div>
    <div className='col-md-6'>
    <OwlCarousel className="owl-theme" {...options}>
     <div className=" testimonial-carousel wow fadeIn" data-wow-delay=".5s">
              <div className="testimonial-item border p-4">
                <div className="d-flex align-items-center">
                  <div className="">
                    <img src="assets/img/testimonial-1.jpg" alt="" />
                  </div>
                  <div className="ms-4">
                    <h4 className="text-secondary">MidWest Distribution</h4>
                    <p className="m-0 pb-3">Operations Manager</p>
                    <div className="d-flex pe-5">
                      <i className="fas fa-star me-1 text-primary" />
                      <i className="fas fa-star me-1 text-primary" />
                      <i className="fas fa-star me-1 text-primary" />
                      <i className="fas fa-star me-1 text-primary" />
                      <i className="fas fa-star me-1 text-primary" />
                    </div>
                  </div>
                </div>
                <div className="border-top mt-4 pt-3">
                  <p className="mb-0">
                  Their clear communication, attention to detail, and dedication to achieving their 
                  goals have made our collaboration seamless and rewarding.
                  </p>
                </div>
              </div>
            </div>

    </OwlCarousel>
    </div>
    {/* <div className='col-md-3'>
    <OwlCarousel className="owl-theme" {...options}>
     <div className=" testimonial-carousel wow fadeIn" data-wow-delay=".5s">
              <div className="testimonial-item border p-4">
                <div className="d-flex align-items-center">
                  <div className="">
                    <img src="assets/img/testimonial-1.jpg" alt="" />
                  </div>
                  <div className="ms-4">
                    <h4 className="text-secondary">Client Name</h4>
                    <p className="m-0 pb-3">Profession</p>
                    <div className="d-flex pe-5">
                      <i className="fas fa-star me-1 text-primary" />
                      <i className="fas fa-star me-1 text-primary" />
                      <i className="fas fa-star me-1 text-primary" />
                      <i className="fas fa-star me-1 text-primary" />
                      <i className="fas fa-star me-1 text-primary" />
                    </div>
                  </div>
                </div>
                <div className="border-top mt-4 pt-3">
                  <p className="mb-0">
                    Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut
                    interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo
                    cursus, ac viverra eros morbi quis quam mi.
                  </p>
                </div>
              </div>
            </div>

    </OwlCarousel>
    </div> */}
    {/* <div className='col-md-3'>
    <OwlCarousel className="owl-theme" {...options}>
     <div className=" testimonial-carousel wow fadeIn" data-wow-delay=".5s">
              <div className="testimonial-item border p-4">
                <div className="d-flex align-items-center">
                  <div className="">
                    <img src="assets/img/testimonial-1.jpg" alt="" />
                  </div>
                  <div className="ms-4">
                    <h4 className="text-secondary">Client Name</h4>
                    <p className="m-0 pb-3">Profession</p>
                    <div className="d-flex pe-5">
                      <i className="fas fa-star me-1 text-primary" />
                      <i className="fas fa-star me-1 text-primary" />
                      <i className="fas fa-star me-1 text-primary" />
                      <i className="fas fa-star me-1 text-primary" />
                      <i className="fas fa-star me-1 text-primary" />
                    </div>
                  </div>
                </div>
                <div className="border-top mt-4 pt-3">
                  <p className="mb-0">
                    Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut
                    interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo
                    cursus, ac viverra eros morbi quis quam mi.
                  </p>
                </div>
              </div>
            </div>

    </OwlCarousel>
    </div> */}
    
    </div>
  );
};
const Testimonial=()=>{
    return(
        <>
        {/* Testimonial Start */}
        <div className="container-fluid testimonial py-5 mb-5">
          <div className="container">
            <div
              className="text-center mx-auto pb-5 wow fadeIn"
              data-wow-delay=".3s"
              style={{ maxWidth: 600 }}
            >
              <h5 className="text-primary fs-2">Our Testimonial</h5>
              <h1>Our Client Saying!</h1>
            </div>
            <TestimonialCarousel/>
          </div>
        </div>
        {/* Testimonial End */}
      </>
    )
}
export default Testimonial;