import {useState} from 'react';
import { Link } from 'react-router-dom';



function Header({isActive,setActive}) {

  return (
   
    <>
  
  <>
  {/* Topbar Start */}
  <div className="container-fluid bg-dark py-2 d-none d-md-flex" style={{height:"45px"}}>
    <div className="container">
      <div className="d-flex justify-content-between topbar">
        <div className="top-info">
          <small className="me-3 text-white-50">
            <Link to="#" />
            <i className="fas fa-map-marker-alt me-2 text-secondary" />
            Mehdipatnam, Hyderabad -500028
          </small>
          <small className="me-3 text-white-50">
            <Link to="#" />
            <i className="fas fa-envelope me-2 text-secondary" />
            info@overseasitservices.com
          </small>
        </div>
        {/* <div id="note" className="text-secondary d-none d-xl-flex">
          <small>Note : We help you to Grow your Business</small>
        </div> */}
        <ul className="top-link">
          <Link
            to=""
            className="bg-light nav-fill btn btn-sm-square rounded-circle"
          >
          <i className="fab fa-facebook-f text-primary" />
          </Link>
          <Link
            to=""
            className="bg-light nav-fill btn btn-sm-square rounded-circle"
          >
          <i className="fab fa-twitter text-primary" />
          </Link>
          <Link
            to=""
            className="bg-light nav-fill btn btn-sm-square rounded-circle"
          >
          <i className="fab fa-instagram text-primary" />
          </Link>
          <Link
            to=""
            className="bg-light nav-fill btn btn-sm-square rounded-circle me-0"
          >
          <i className="fab fa-linkedin-in text-primary" />
          </Link>
        </ul>
      </div>
    </div>
  </div>
  {/* Topbar End */}
</>


<>
  {/* Navbar Start */}
  <div className="container-fluid bg-primary">
    <div className="container">
      <nav className="navbar navbar-dark navbar-expand-lg py-0">
        <Link to="/" className="navbar-brand">
          <img src="\assets\img\WebsitePhotos\overseas-main-logo.jpeg" alt="Logo" width="200" height="100" style={{ borderRadius: '15px',  }}/>
        </Link>
        <button
          type="button"
          className="navbar-toggler me-0"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse bg-transparent"
          id="navbarCollapse"
        >
          <ul className="navbar-nav ms-auto mx-xl-auto p-0">

            <li className="nav-item">
              <Link to="/"  className={isActive==="home"?`nav-link activeTab`:'nav-link'}  onClick={()=>setActive("home")} id="home">Home</Link>
            </li>
            <li className="nav-item">
              <Link to='/about'   className={isActive==="about"?`nav-link activeTab`:'nav-link'}  onClick={()=>setActive("about")}   id="about">About</Link>
            </li>
            <li className="nav-item">
              <Link to="/service" className={isActive==="service"?`nav-link activeTab`:'nav-link'}  onClick={()=>setActive("service")} >Services</Link>
            </li>
            <li className="nav-item">
              <Link to="/career" className={isActive==="career"?`nav-link activeTab`:'nav-link'}  onClick={()=>setActive("career")} >Career</Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className={isActive==="contact"?`nav-link activeTab`:'nav-link'}  onClick={()=>setActive("contact")} >Contact</Link>
            </li>

            {/* <a
              href="index.html"
              className="nav-item nav-link active text-secondary"
            >
              Home
            </a> */}
            {/* <a href="about.html" className="nav-item nav-link">
              About
            </a>
            <a href="service.html" className="nav-item nav-link">
              Services
            </a>
            <a href="project.html" className="nav-item nav-link">
              Projects
            </a>
            <div className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Pages
              </a>
              <div className="dropdown-menu rounded">
                <a href="blog.html" className="dropdown-item">
                  Our Blog
                </a>
                <a href="team.html" className="dropdown-item">
                  Our Team
                </a>
                <a href="testimonial.html" className="dropdown-item">
                  Testimonial
                </a>
                <a href="404.html" className="dropdown-item">
                  404 Page
                </a>
              </div>
            </div>
            <a href="contact.html" className="nav-item nav-link">
              Contact
            </a> */}
          </ul>
        </div>
        <div className="d-none d-xl-flex flex-shirink-0">
          <div
            id="phone-tada"
            className="d-flex align-items-center justify-content-center me-4"
          >
            <Link to="" className="position-relative animated tada infinite">
              <i className="fa fa-phone-alt text-white fa-2x" />
              <div
                className="position-absolute"
                style={{ top: "-7px", left: 20 }}
              >
                <span>
                  <i className="fa fa-comment-dots text-secondary" />
                </span>
              </div>
            </Link>
          </div>
          <div className="d-flex flex-column pe-4 border-end">
            <span className="text-white-50">Have any questions?</span>
            <span className="text-secondary">Call:+91 85220 50522 </span>
          </div>
          <div className="d-flex align-items-center justify-content-center ms-4 ">
            <Link to="">
              <i className="bi bi-search text-white fa-2x" />{" "}
            </Link>
          </div>
        </div>
      </nav>
    </div>
  </div>
  {/* Navbar End */}
</>

  
</>

  )
}



export default Header
