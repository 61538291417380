import React from "react";
import { useNavigate } from "react-router-dom";
import TeamLeader from "../components/TeamLeader";

function About({setActive}) {
  const navigate=useNavigate();

  const navigateToAbout=()=>{
setActive("about");
navigate("/about")
  }
  return (
    <>
  <>
  {/* Page Header Start */}
  <div className="container-fluid page-header py-5">
    <div className="container text-center py-5">
      <h1 className="display-2 text-white mb-4 animated slideInDown">
        About Us
      </h1>
      <nav aria-label="breadcrumb animated slideInDown">
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          
          <li className="breadcrumb-item" aria-current="page">
            About
          </li>
        </ol>
      </nav>
    </div>
  </div>
  {/* Page Header End */}
</>

      {/* About Start */}
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div
              className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <div className="h-100 position-relative">
                <img
                  src="assets\img\WebsitePhotos\About us Section1.jpg"
                  className="img-fluid w-75 rounded"
                  alt=""
                  style={{ marginBottom: "25%" }}
                />
                <div
                  className="position-absolute w-75"
                  style={{ top: "25%", left: "25%" }}
                >
                  <img
                    src="assets\img\WebsitePhotos\About us Section2.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <h5 className="text-primary fs-2">About Us</h5>
              <h1 className="mb-4">
              About Overseas IT Services & It’s Innovative Solutions

              </h1>
              <p className="fs-5">
              With our extensive expertise and dedicated team of professionals, we deliver tailored solutions that meet the unique needs of each client. From data entry and customer support to software development and IT infrastructure management, we have the capabilities to handle diverse requirements with precision and excellence.

              </p>
              <p className="mb-4 fs-5">
              Our commitment to quality, reliability, and customer satisfaction sets us apart as a leading offshore service provider in the industry. Partner with Overseas IT Services to experience the benefits of seamless outsourcing and unlock new opportunities for your business growth.

              </p>
              {/* <button
                onClick={navigateToAbout}
                className="btn btn-secondary rounded-pill px-5 py-3 text-white"
              >
                More Details
              </button> */}
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
    
      {/* Team Start */}
   <TeamLeader/>

      {/* Team End */}
    </>
  );
}

export default About;
