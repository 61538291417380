
import Router from "./components/Router";
function App() {
  
  return (
     <div className="router">
      <Router/>
     </div>
  );
}

export default App;
