import React from "react";
import { useState } from "react";
import { sendPostReq, sendPostFileReq } from "../DAL/http"

const ApplyJobForm = () => {
  const [file, setFile] = useState()
  const [fullName, setFullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");

  const submitJobPost = async (e) => {
    debugger
    e.preventDefault();
    if (file !== undefined && file.name !== undefined && file.name !== '') {
      debugger
      // var ext = (file.name).split('.').pop();
      // let filename = "resume-upload-" + Math.random().toString(36).slice(2);
      // file.name = filename + "." + ext
      const formData1 = new FormData();
      formData1.append('file', file);
      // formData1.append('fileName', file.name);
      await sendPostFileReq("file-upload/resume-upload", formData1)
        .then(async (response) => {
          debugger
          if ((response.statusCode) === 200) {
            debugger
            let formData = {
              fullName: fullName,
              phoneNumber: mobile,
              email: email,
              message: comments,
              jobId: ((sessionStorage.getItem('jobId'))),
              resume: response.imageUrl,
              createdDate : new Date()
            };
            await sendPostReq("job-applications", (formData))
              .then((response1) => {
                debugger
                setFullName("");
                setMobile("");
                setEmail("");
                setComments("");
                setFile("");
                alert(JSON.parse(response1));
                console.log("Saved Successful:", response1);

              })
              .catch((error) => {
                console.error("Error saving user:", error);
              });
          }
        })
        .catch((error) => {
          console.error("Error saving user:", error);
        });
    } else {
      alert("Upload Resume");
    }
  };

  function handleChange(event) {
    setFile(event.target.files[0])
  }
  return (
    <div className="container py-5">
      <div className="row">
      <div className="col-md-6 m-auto">
      <div style={{marginBottom:"50px"}}>
        <h2 className="mb-3">You Are Applying For:<br/>{sessionStorage.getItem('jobName')} </h2>
        <div  style={{width:"100%"}}>
      <form onSubmit={submitJobPost} >
        <div className="mb-3">
          <label htmlFor="fullname" className="form-label">Full Name:</label>
          <input type="text" className="form-control" id="fullname" required
            onChange={(e) => setFullName(e.target.value)} value={fullName} />
        </div>
        <div className="mb-3">
          <label htmlFor="phonenumber" className="form-label">Phone Number:</label>
          <input type="tel" className="form-control" id="phonenumber" pattern="[0-9]{10}" required
            onChange={(e) => setMobile(e.target.value)} value={mobile} />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email Address:</label>
          <input type="email" className="form-control" id="email" required
            onChange={(e) => setEmail(e.target.value)} value={email} />
        </div>
        <div className="mb-3">
          <label htmlFor="comments" className="form-label">Comments:</label>
          <textarea className="form-control" id="comments" rows="3"
            onChange={(e) => setComments(e.target.value)} value={comments}></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="resume" className="form-label">Upload Resume:</label>
          <input type="file" className="form-control" id="resume" accept='application/msword, application/pdf' onChange={handleChange} />
        </div>
        <div className="d-flex ">

        </div>
        <button type="submit" className="btn btn-primary mt-4 mb-3" style={{width:"300px"}}>Submit My Details</button>
      </form>
      </div>
      </div>
   
      </div>
        </div>
      
     
    </div>
  )
}

export default ApplyJobForm