
import React,{ useState} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "../pages";
import About from "../pages/about";
import Contact from "../pages/contact";
import Service from "../pages/service";
import Header from "./Header";
import Footer from "./Footer";
import Career from "../pages/career";
import JobDetails from "../pages/jobDetailPage";
import NotFound from "./NotFound";
import ApplyJobForm from "./ApplyJobForm";
import ScrollToTop from "./ScroolToTap";

function Router() {
  const[isActive,setActive]=useState("home");

  return (
    <BrowserRouter>
    <ScrollToTop/>
      <Header isActive={isActive} setActive={setActive}/>
      <Routes>
        <Route   path="/" element={<Index setActive={setActive}/>} />
        <Route path="/about" element={<About setActive={setActive}/>}/>
        <Route path="/contact" element={<Contact />} />
        <Route path="/service" element={<Service />} />
        <Route path="/career" element={<Career/>}/>
        <Route path="/job-details" element={<JobDetails/>}/>
        <Route path="/apply-now" element={<ApplyJobForm/>}/>
        <Route path="/*" element={<NotFound />} />
      </Routes>
     <Footer isActive={isActive} setActive={setActive}/>
    </BrowserRouter>
  );
}

export default Router;
