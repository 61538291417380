import React from 'react';
import OwlCarousel from 'react-owl-carousel-rtl';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const TeamCarousel= () => {
  const options = {
    loop: true,
    margin: 10,
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1024: {
        items: 1,
      },
    },
  };

  return (
    <div className="container">
      <div className='row'>

    
      <div className='col-md-3'>

     
      
      <OwlCarousel className="owl-theme" {...options}>
        <div>
          <div className="rounded team-item">
            <div className="team-content">
              <div className="team-img-icon">
                <div className="team-img rounded-circle">
                  <img
                    src="assets/img/team-1.jpg"
                    className="img-fluid w-100 rounded-circle"
                    alt=""
                  />
                </div>
                <div className="team-name text-center py-3">
                  <h4 className="">Full Name</h4>
                  <p className="m-0">Designation</p>
                </div>
                <div className="team-icon d-flex justify-content-center pb-4">
                  <a
                    className="btn btn-square btn-secondary text-white rounded-circle m-1"
                    href=""
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a
                    className="btn btn-square btn-secondary text-white rounded-circle m-1"
                    href=""
                  >
                    <i className="fab fa-twitter" />
                  </a>
                  <a
                    className="btn btn-square btn-secondary text-white rounded-circle m-1"
                    href=""
                  >
                    <i className="fab fa-instagram" />
                  </a>
                  <a
                    className="btn btn-square btn-secondary text-white rounded-circle m-1"
                    href=""
                  >
                    <i className="fab fa-linkedin-in" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add more items as needed */}
      </OwlCarousel>
      </div>
      <div className='col-md-3'>
      <OwlCarousel className="owl-theme" {...options}>
        <div>
          <div className="rounded team-item">
            <div className="team-content">
              <div className="team-img-icon">
                <div className="team-img rounded-circle">
                  <img
                    src="assets/img/team-1.jpg"
                    className="img-fluid w-100 rounded-circle"
                    alt=""
                  />
                </div>
                <div className="team-name text-center py-3">
                  <h4 className="">Full Name</h4>
                  <p className="m-0">Designation</p>
                </div>
                <div className="team-icon d-flex justify-content-center pb-4">
                  <a
                    className="btn btn-square btn-secondary text-white rounded-circle m-1"
                    href=""
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a
                    className="btn btn-square btn-secondary text-white rounded-circle m-1"
                    href=""
                  >
                    <i className="fab fa-twitter" />
                  </a>
                  <a
                    className="btn btn-square btn-secondary text-white rounded-circle m-1"
                    href=""
                  >
                    <i className="fab fa-instagram" />
                  </a>
                  <a
                    className="btn btn-square btn-secondary text-white rounded-circle m-1"
                    href=""
                  >
                    <i className="fab fa-linkedin-in" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add more items as needed */}
      </OwlCarousel>
       </div>
       <div className='col-md-3'>
      <OwlCarousel className="owl-theme" {...options}>
        <div>
          <div className="rounded team-item">
            <div className="team-content">
              <div className="team-img-icon">
                <div className="team-img rounded-circle">
                  <img
                    src="assets/img/team-1.jpg"
                    className="img-fluid w-100 rounded-circle"
                    alt=""
                  />
                </div>
                <div className="team-name text-center py-3">
                  <h4 className="">Full Name</h4>
                  <p className="m-0">Designation</p>
                </div>
                <div className="team-icon d-flex justify-content-center pb-4">
                  <a
                    className="btn btn-square btn-secondary text-white rounded-circle m-1"
                    href=""
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a
                    className="btn btn-square btn-secondary text-white rounded-circle m-1"
                    href=""
                  >
                    <i className="fab fa-twitter" />
                  </a>
                  <a
                    className="btn btn-square btn-secondary text-white rounded-circle m-1"
                    href=""
                  >
                    <i className="fab fa-instagram" />
                  </a>
                  <a
                    className="btn btn-square btn-secondary text-white rounded-circle m-1"
                    href=""
                  >
                    <i className="fab fa-linkedin-in" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add more items as needed */}
      </OwlCarousel>
      </div>
        <div className='col-md-3'>
      <OwlCarousel className="owl-theme" {...options}>
        <div>
          <div className="rounded team-item">
            <div className="team-content">
              <div className="team-img-icon">
                <div className="team-img rounded-circle">
                  <img
                    src="assets/img/team-1.jpg"
                    className="img-fluid w-100 rounded-circle"
                    alt=""
                  />
                </div>
                <div className="team-name text-center py-3">
                  <h4 className="">Full Name</h4>
                  <p className="m-0">Designation</p>
                </div>
                <div className="team-icon d-flex justify-content-center pb-4">
                  <a
                    className="btn btn-square btn-secondary text-white rounded-circle m-1"
                    href=""
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a
                    className="btn btn-square btn-secondary text-white rounded-circle m-1"
                    href=""
                  >
                    <i className="fab fa-twitter" />
                  </a>
                  <a
                    className="btn btn-square btn-secondary text-white rounded-circle m-1"
                    href=""
                  >
                    <i className="fab fa-instagram" />
                  </a>
                  <a
                    className="btn btn-square btn-secondary text-white rounded-circle m-1"
                    href=""
                  >
                    <i className="fab fa-linkedin-in" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add more items as needed */}
      </OwlCarousel>
      </div>
    </div>
    </div>
  );
};




function TeamLeader() {
  return (<>
      {/* Team Start */}
      <div className="container-fluid py-5 mb-5 team">
        <div className="container">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: 600 }}
          >
            <h5 className="text-primary fs-2">Our Team</h5>
            <h1>Meet our expert Team</h1>
          </div>
          <div className="team-carousel wow fadeIn" data-wow-delay=".5s">
             <TeamCarousel/>
          </div>
        </div>
      </div>

      {/* Team End */}
      </>
  )
}

export default TeamLeader;
 
