import React, { useEffect, useState } from "react";
import { sendPostReq } from "../DAL/http";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
    createdDate:new Date()
  });

  // const[error,setError]=useState("");

  const [submitData, setSubmitData] = useState(null);

  useEffect(() => {
    if (submitData) {
      alert(submitData.message);
    }
  }, [submitData]);

  const validationErrors = (data) => {
    const formError = {};
    if (data.name === "") {
      formError.name = "Username is Required field *";
    }
    if (data.email === "") {
      formError.email = "Email is Required field *";
    }
    if (data.phoneNumber === "") {
      formError.phoneNumber = "phoneNumber is Required field *";
    }
    if (data.message === "") {
      formError.message = "message is Required field *";

      return formError;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((p) => ({ ...p, [name]: value }));
  };

  const onSubmitContact = async (e) => {
    e.preventDefault();
    //  const errorObject= validationErrors(formData);

    const res = await sendPostReq("contact", formData);
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
      createdDate:new Date()
    });

    setSubmitData(res);
  };

  return (
    <>
      {/* Page Header Start */}
      <div className="container-fluid contact py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            Contact Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>

              <li className="breadcrumb-item" aria-current="page">
                Contact
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* Page Header End */}

      {/* Contact Start */}
      <div className="container-fluid py-5 mt-3">
        <div className="container py-5">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: 900 }}
          >
            <h5 className="text-primary fs-2">Contact Us</h5>
            <h2 className="mb-3">Get Answers To Any Of Your Queries</h2>
            <div>
              <p className="mb-2 fs-5">
                If you may have any query, questions or clarifications please
                feel free to contact us, and learn more about how we can support
                your backend business processes.
              </p>
            </div>
          </div>
          <div className="contact-detail position-relative p-5">
            <div className="row g-5 mb-5 justify-content-center">
              <div
                className="col-xl-4 col-lg-6 wow fadeIn"
                data-wow-delay=".3s"
              >
                <div className="d-flex bg-light p-3 rounded">
                  <div
                    className="flex-shrink-0 btn-square bg-secondary rounded-circle"
                    style={{ width: 64, height: 64 }}
                  >
                    <i className="fas fa-map-marker-alt text-white" />
                  </div>
                  <div className="ms-3">
                    <h4 className="text-primary">Address</h4>
                    <a
                      href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d475.9434377463554!2d78.43972513815054!3d17.385493587925346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb97621c405d57%3A0x555f69218b685a84!2sOverseas%20IT%20services!5e0!3m2!1sen!2sus!4v1708621533092!5m2!1sen!2sus"
                      target="_blank"
                      className="h5"
                    >
                      Mehdipatnam, Hyd, INDIA.
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6 wow fadeIn"
                data-wow-delay=".5s"
              >
                <div className="d-flex bg-light p-3 rounded">
                  <div
                    className="flex-shrink-0 btn-square bg-secondary rounded-circle"
                    style={{ width: 64, height: 64 }}
                  >
                    <i className="fa fa-phone text-white" />
                  </div>
                  <div className="ms-3">
                    <h4 className="text-primary">Call Us</h4>
                    <a
                      className="h5"
                      href="tel:+91 85220 50522"
                      target="_blank"
                    >
                      +91 85220 50522
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6 wow fadeIn"
                data-wow-delay=".7s"
              >
                <div className="d-flex bg-light p-3 rounded">
                  <div
                    className="flex-shrink-0 btn-square bg-secondary rounded-circle"
                    style={{ width: 50, height: 64 }}
                  >
                    <i className="fa fa-envelope text-white" />
                  </div>
                  <div className="ms-3">
                    <h4 className="text-primary">Email Us</h4>
                    <a
                      className="h5"
                      href="mailto:info@overseasitservices.com
"
                      target="_blank"
                    >
                      info@overseasitservices.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-6 wow fadeIn" data-wow-delay=".3s">
                <div className="p-5 h-100 rounded contact-map">
                  <iframe
                    className="rounded w-100 h-100"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d475.9434377463554!2d78.43972513815054!3d17.385493587925346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb97621c405d57%3A0x555f69218b685a84!2sOverseas%20IT%20services!5e0!3m2!1sen!2sus!4v1708621533092!5m2!1sen!2sus"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>
              <div className="col-lg-6 wow fadeIn" data-wow-delay=".5s">
                <form
                  className="p-5 rounded contact-form"
                  onSubmit={onSubmitContact}
                >
                  <div className="mb-4">
                    <input
                      onChange={handleChange}
                      name="name"
                      value={formData.name}
                      type="text"
                      className="form-control border-0 py-3"
                      placeholder="Your Name"
                    />
                    {/* {error.name && <span className='text-danger'>{error.name}</span>} */}
                  </div>
                  <div className="mb-4">
                    <input
                      onChange={handleChange}
                      type="email"
                      className="form-control border-0 py-3"
                      placeholder="Your Email"
                      name="email"
                      value={formData.email}
                    />
                    {/* {error.email && <span className='text-danger'>{error.email}</span>} */}
                  </div>
                  <div className="mb-4">
                    <input
                      onChange={handleChange}
                      type="text"
                      className="form-control border-0 py-3"
                      placeholder="Phone Number"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                    />
                    {/* {error.phoneNumber && <span className='text-danger'>{error.phoneNumber}</span>} */}
                  </div>

                  <div className="mb-4">
                    <textarea
                      onChange={handleChange}
                      className="w-100 form-control border-0 py-3"
                      rows={6}
                      cols={10}
                      placeholder="Message"
                      defaultValue={handleChange}
                      name="message"
                      value={formData.message}
                    />
                    {/* {error.message && <span className='text-danger'>{error.message}</span>} */}
                  </div>
                  <div className="text-start">
                    <button
                      className="btn bg-primary text-white py-3 px-5"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact End */}
    </>
  );
}

export default Contact;
