import React, { useState, useEffect } from "react";
import { sendGetReq } from "../DAL/http";
import { Button } from "primereact/button";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { useNavigate } from 'react-router-dom';
import { classNames } from "primereact/utils";
import moment from 'moment';
function Career() {
  const [activeJobsList, setJobs] = useState([]);
  const [status, setStatus] = useState("active");
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [serviceImageUrl, setServiceImageUrl] = useState('http://localhost:3039/');
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 5,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: "", matchMode: "contains" },
      // 'country.name': { value: '', matchMode: 'contains' },
      email: { value: "", matchMode: "contains" },
      // 'representative.name': { value: '', matchMode: 'contains' }
    },
  });



  const formatDate = (date) => {
    
    const formattedDate = moment(date).format("DD/MM/YYYY");
    return formattedDate
  }

  const navigate = useNavigate();

  let networkTimeout = null;

  useEffect(() => {
    loadLazyData();
  }, [lazyState]);


  const imageBodyTemplate = (product) => {
    return <img src={`https://primefaces.org/cdn/primereact/images/product/${product.image}`} alt={product.image} className="w-6rem shadow-2 border-round" />;
};
  const loadLazyData = () => {
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(() => {
      sendGetReq(
        "job-openings/" +
        status +
        "/" +
        [lazyState.page] +
        "/" +
        [lazyState.rows]
      )
        .then((data) => {
          debugger;
          setTotalRecords(data.count);
          setJobs(data.list);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error getting all Users:", error);
        });
      // CustomerService.getCustomers({ lazyEvent: JSON.stringify(lazyState) }).then((data) => {
      // });
    }, Math.random() * 1000 + 250);
  };

  const onPage = (event) => {
    debugger;
    event.page = event.first;
    setlazyState(event);
  };

  const onViewJobDetails = (rowData) => {
    debugger
    console.log(rowData);
    sessionStorage.setItem("jobId", rowData._id);
    sessionStorage.setItem("jobName", rowData.title);
    sessionStorage.setItem("jobEditorText", rowData.editorText);
    navigate('/job-details');
  }

  const itemTemplate = (item, index) => {
    return (
      <div className="" key={item.id} style={{borderBottom:"1px solid  grey",marginBottom:"40px",marginTop:"40px"}}>
        <div className="job-container">
          <div className="img-container" style={{ marginRight: "10px" }}>
            <img
              src={`https://services.overseasitservices.com/job-images/${item.image}`}
              alt=""
              className="img-bg rounded"
            />
          </div>
          <div className="text-container col-md-12" style={{ marginTop: "13px" }}>
            <div className="">
              <p className="fs-7">{formatDate(item.modifiedDate)}</p>
            </div>
            <div className="job-text-container">
              <h3 className="fs-5">{item.title}</h3>
              <p className="fs-9" style={{maxWidth:"500px"}}>{item.desc}</p>
            </div>
            <div>
              <button className="view-button" type="button" onClick={() => onViewJobDetails(item)}>View Details</button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const listTemplate = (items) => {
    if (!items || items.length === 0) return null;

    let list = items.map((product, index) => {
      return itemTemplate(product, index);
    });

    return <div className="grid grid-nogutter">{list}</div>;
  };

  return (
    //  page-header it is background image
    <>
      {/* Page Header Start */}
      <div className="container-fluid page-header4 py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
           Job Opportunities
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>

              <li className="breadcrumb-item" aria-current="page">
                Career
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* Page Header End */}

      <div className="container-fluid py-5 mt-3">
        <div className="container py-5">
          <div className="row">
          <div className="col-md-1">

</div>
<div className="col-md-10">
  <div className="text-center">
    <h3 className="fs-2 mb-2">Currently We Have The Following Job Vaccancies</h3>
    <p className="fs-5">
    Currently, we have the following job vacancies available for eager candidates seeking new opportunities. Explore current positions and embark on a rewarding career journey with Overseas IT Services, from entry-level positions to specialized roles, we offer a variety of opportunities for individuals with different skill sets and backgrounds. Grow yourself, join our dynamic team and contribute your talents to our growing organization.
    </p>
  </div>
</div>
<div className="col-md-1">

</div>
</div>
          </div>
   
      </div>

      {/* {loading ? (<div className="text-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>)

        : (<div className="container-fluid py-5 mt-3">
          <div className="container py-5">
            <ul>
              {activeJobsList.map((item) => (<JobItem key={item.id} item={item} />))}
            </ul>
          </div>
        </div>)} */}

      <div className="job-item-container w-100 p-20" style={{ border: "none" }}>
        <div className="col-md-8">


          <div className="card p-4">
            <DataView
              value={activeJobsList}
              listTemplate={listTemplate}
              paginator
              rows={5}
              lazy
              totalRecords={totalRecords}
              onPage={onPage}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Career;
