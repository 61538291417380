import axios from "axios"

const base_URL = "https://www.services.overseasitservices.com/"
// const base_URL = "http://localhost:3039/"


// const HTTP={
//     get:axios.get,
//     post:axios.post,
    
    
// }
export const sendPostFileReq = async (requestUrl, data) => {
  const url = base_URL + requestUrl;
  const getApiData = async () => {
    debugger
    const requestsOptions = {
      method: "POST",
      body: (data),
      // headers: {
      //   'content-type': 'multipart/form-data',
      // },
    };
    console.log("Executing Post");
    return await fetch(url, requestsOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("Response Data: " + JSON.stringify(data));
        return data;
      })
      .catch((err) => {
        console.log("Emaina Error:", err);
        throw err;
      });
  };
  return getApiData();
};

export const sendPostReq = async (requestURL,data)=>{
    const main_URL = base_URL+requestURL

    const requestOptions={
        method:"POST",
        headers:{
            "Content-Type":"application/json"      
        },
        body:JSON.stringify(data)
    }

    try{
     const respone=  await fetch(main_URL,requestOptions);
     
     const responseData= await respone.json();
     console.log(responseData);
     return responseData
        
    }catch(e){
        console.log(e.message)
    }
   
   

}



export const sendGetReq = async (requestURL)=>{
    const main_URL = base_URL+requestURL

    const requestOptions={
        method:"GET",
       
    }

    try{
     const respone=  await fetch(main_URL,requestOptions);
     
     const responseData= await respone.json();
  
     return responseData
        
    }catch(e){
        console.log(e.message)
    }
   
   

}

