import React from "react";
import {Link,useNavigate} from "react-router-dom";

import Testimonial from "../components/Testimonial";

function Index({setActive}) {
 const navigate= useNavigate();
  

 function handleChange() {
  setActive("about"); // Set the active state to "about"
  navigate("/about"); // Navigate to the "/about" route
}

function navigateToContact(){
  setActive("contact");
  navigate("contact")
}
  return (
    <>
      {/* Carousel Start */}
      <div className="container-fluid px-0">
        <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
          <ol className="carousel-indicators">
            <li
              data-bs-target="#carouselId"
              data-bs-slide-to={0}
              className="active"
              aria-current="true"
              aria-label="First slide"
            />
            <li
              data-bs-target="#carouselId"
              data-bs-slide-to={1}
              aria-label="Second slide"
            />
          </ol>
          <div className="carousel-inner" role="listbox">
            <div className="carousel-item active">
              <img
                src="assets\img\WebsitePhotos\Hero Image 1.jpg"
                className="img-fluid w-100"
                alt="First slide"
              />
              <div className="carousel-caption">
                <div className="container carousel-content">
                  <h1 className="text-secondary h4 animated fadeInUp fs-3 mb-3">
                  Innovative & Creative IT Solutions

                  </h1>
                  <h2 className="text-white display-1 mb-5 animated fadeInRight">
                  The Best IT Services Company

                  </h2>
                  <h3 className="text-secondary h4 animated fadeInUp fs-3 mb-3">
                  Driving Progress & Delivering Solutions

                  </h3>
                  <p className="mb-4 text-white fs-5 animated fadeInDown fs-5">
                  We offer a wide range of IT services to businesses worldwide, helping them streamline operations, enhance efficiency, and reduce costs.

                  </p>
                  <span className="me-2">
                    <button
                      type="button"
                      className="px-4 py-sm-3 px-sm-5 btn btn-success rounded-pill carousel-content-btn1 animated fadeInLeft"
                    >
                      Read More
                    </button>
                  </span>
                  <span onClick={navigateToContact} className="ms-2">
                    <button
                      type="button"
                      className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight"
                    >
                      Contact Us
                    </button>
                  </span>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="assets\img\WebsitePhotos\Hero Image 2.jpg"
                className="img-fluid w-100"
                alt="Second slide"
              />
              <div className="carousel-caption">
                <div className="container carousel-content">
                  <h2 className="text-secondary h4 animated fadeInUp fs-3">
                    Best IT Solutions
                  </h2>
                  <h1 className="text-white display-1 mb-4 animated fadeInLeft">
                  We Specialize In OffShore Solutions

                  </h1>
                  <h2 className="text-secondary h4 animated fadeInUp fs-3 mb-4">
                  Your IT Partners for Success

                  </h2>
                  <p className="mb-4 text-white fs-5 animated fadeInDown fs-5">
                  At Overseas IT Services, we specialize in providing offshore solutions for backend business processes.

                  </p>
                  <span  className="me-2">
                    <button
                      type="button"
                      className="px-4 py-sm-3 px-sm-5 btn btn-success rounded-pill carousel-content-btn1 animated fadeInLeft"
                    >
                      Read More
                    </button>
                  </span>
                  <span onClick={navigateToContact} className="me-2" >
                    <button
                      type="button"
                      className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight"
                    >
                      Contact Us
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselId"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselId"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      {/* Carousel End */}
     
      {/* About Start */}
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div
              className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <div className="h-100 position-relative">
                <img
                  src="assets\img\WebsitePhotos\About us Section2.jpg"
                  className="img-fluid w-75 rounded"
                  alt=""
                  style={{ marginBottom: "25%" }}
                />
                <div
                  className="position-absolute w-75"
                  style={{ top: "25%", left: "25%" }}
                >
                  <img
                    src="assets\img\WebsitePhotos\About us Section1.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <h5 className="text-primary fs-2">About Us</h5>
              <h1 className="mb-4">
              About Overseas IT Services & It’s Innovative Solutions

              </h1>
              <p className="fs-5">
              With our extensive expertise and dedicated team of professionals, we deliver tailored solutions that meet the unique needs of each client. From data entry and customer support to software development and IT infrastructure management, we have the capabilities to handle diverse requirements with precision and excellence.

              </p>
              <p className="mb-4 fs-5">
              Our commitment to quality, reliability, and customer satisfaction sets us apart as a leading offshore service provider in the industry. Partner with Overseas IT Services to experience the benefits of seamless outsourcing and unlock new opportunities for your business growth.

              </p>
              <button 
                 onClick={handleChange}
                className="btn btn-secondary rounded-pill px-5 py-3 text-white"
              >
                More Details
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      {/* Services Start */}
      <div className="container-fluid services py-5 mb-5">
        <div className="container">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: 1000 }}
          >
            <h5 className="text-primary mb-2 fs-2">Our Services</h5>
            <h1 className="mb-3">Services That Suit Your Unique Business Needs
</h1>
<p className="fs-5 ">At Overseas IT Services, we provide a comprehensive range of solutions to meet your business needs. And we are dedicated to delivering excellence in every aspect of our services, helping you streamline operations, enhance efficiency, and drive success for your business</p>
          </div>
          <div className="row g-5 services-inner">
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-headset fa-7x mb-4 text-primary"/>
                    <h4 className="mb-3">Customer Services</h4>
                    <p className="mb-4">
                    Our customer service team is committed to providing exceptional support to ensure client satisfaction. From handling inquiries to resolving issues promptly, we prioritize delivering outstanding service that exceeds expectations.

                    </p>
                    <button
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".5s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-dollar-sign fa-7x mb-4 text-primary" />
                    <h4 className="mb-3">Accounts & Billing
</h4>
                    <p className="mb-4">
                    Let us handle all aspects of your accounts and billing processes with accuracy and precision. Our experienced accounting professionals manage financial transactions (from bookkeeping to reporting), invoicing, billing, and ensuring accuracy, compliance, and financial health.

                    </p>
                    <button
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".7s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-chart-line fa-7x mb-4 text-primary" />
                    <h4 className="mb-3">Data Analysis</h4>
                    <p className="mb-4">
                    Unlock the power of data with our expert data analysis services. Our skilled analysts extract valuable insights, identify trends, and make data-driven decisions that drive business growth and performance.

                    </p>
                    <button
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-pencil-ruler fa-7x mb-4 text-primary" />
                    <h4 className="mb-3">Graphic Designing
</h4>
                    <p className="mb-4">
                    Elevate your brand and captivate your audience with our creative graphic design services. From marketing campaigns to branding materials, our talented designers bring ideas to life with stunning designs that leave a lasting impression.

                    </p>
                    <button
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".5s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-keyboard fa-7x mb-4 text-primary" />
                    <h4 className="mb-3"> Data Entry Services
</h4>
                    <p className="mb-4">
                    Efficiently manage vast amounts of information with our reliable data entry services. From inputting data to maintaining databases, we ensure accuracy and reliability, allowing you to streamline processes and focus on core business activities.

                    </p>
                    <button
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".7s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-exchange-alt fa-7x mb-4 text-primary" />
                    <h4 className="mb-3">Returns Process Resource
</h4>
                    <p className="mb-4">
                    We provide comprehensive solutions for managing returns processes efficiently and effectively, ensuring customer satisfaction and maximizing operational efficiency.
                    </p>
                    <button
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".5s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-shopping-cart fa-7x mb-4 text-primary" />
                    <h4 className="mb-3"> Procurement</h4>

                    <p className="mb-4">
                    Our procurement services streamline the purchasing process, helping you source and acquire goods and services efficiently while optimizing costs and ensuring quality.


                    </p>
                    <button
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".5s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-cogs fa-7x mb-4 text-primary" />
                    <h4 className="mb-3"> Product Development

</h4>
                    <p className="mb-4">
                    Partner with us to bring your product ideas to life. Our product development services encompass ideation, prototyping, testing, and launch, helping you innovate and stay ahead in the market.


                    </p>
                    <button
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>

           
          </div>
        </div>
      </div>
      {/* Services End */}
    {/* Why choose Us Start*/}
    <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            
            <div
              className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <h5 className="text-primary fs-2">Why Choose Us</h5>
              <h2 className="mb-4">
              Let’s Work Together And Boost Success for Your Business


              </h2>
              <p className="fs-5">
              At Overseas IT Services, we take pride in being more than just an IT firm. What sets us apart? It represents our solid commitment to your achievement. We work with you every step, understanding your specific difficulties and goals and delivering tailored strategies that provide results.


              </p>
              <p className="mb-4 fs-5">
              With a team of professional experts at the forefront, we offer comprehensive support to keep your business competitive and future-ready. And with a commitment to excellence and a passion for innovation, we are the best choice for all of your technological demands.


              </p>
             
              <a
                href=""
                className="btn btn-secondary rounded-pill px-5 py-3 text-white"
              >
                More Details
              </a>
            </div>

            <div
              className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <div className="h-100 position-relative">
                <img
                  src="assets\img\WebsitePhotos\Why choose us Section1.jpg"
                  className="img-fluid w-75 rounded"
                  alt=""
                  style={{ marginBottom: "25%" }}
                />
                <div
                  className="position-absolute w-75"
                  style={{ top: "25%", left: "25%" }}
                >
                  <img
                    src="assets\img\WebsitePhotos\Why choose us Section2.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    {/* Choose Us End */}
    
      
      <Testimonial/>
    </>
  );
}

export default Index;
