import React from 'react'

function Service() {
  return (
  <>
  {/* Page Header Start */}
  <div className="container-fluid page-header3 py-5">
    <div className="container text-center py-5">
      <h2 className="display-2 text-white mb-4 animated slideInDown">
        Services
      </h2>
      <nav aria-label="breadcrumb animated slideInDown">
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          
          <li className="breadcrumb-item" aria-current="page">
            Services
          </li>
        </ol>
      </nav>
    </div>
  </div>
  {/* Page Header End */}
{/* Services Start */}
<div className="container-fluid services py-5 mb-5">
        <div className="container">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: 1000 }}
          >
            <h5 className="text-primary mb-2 fs-2">Our Services</h5>
            <h1 className="mb-3">Services That Suit Your Unique Business Needs
</h1>
<p className="fs-5 ">At Overseas IT Services, we provide a comprehensive range of solutions to meet your business needs. And we are dedicated to delivering excellence in every aspect of our services, helping you streamline operations, enhance efficiency, and drive success for your business</p>
          </div>
          <div className="row g-5 services-inner">
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-headset fa-7x mb-4 text-primary"/>
                    <h4 className="mb-3">Customer Services</h4>
                    <p className="mb-4">
                    Our customer service team is committed to providing exceptional support to ensure client satisfaction. From handling inquiries to resolving issues promptly, we prioritize delivering outstanding service that exceeds expectations.

                    </p>
                    <button
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".5s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-dollar-sign fa-7x mb-4 text-primary" />
                    <h4 className="mb-3">Accounts & Billing
</h4>
                    <p className="mb-4">
                    Let us handle all aspects of your accounts and billing processes with accuracy and precision. Our experienced accounting professionals manage financial transactions (from bookkeeping to reporting), invoicing, billing, and ensuring accuracy, compliance, and financial health.

                    </p>
                    <button
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".7s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-chart-line fa-7x mb-4 text-primary" />
                    <h4 className="mb-3">Data Analysis</h4>
                    <p className="mb-4">
                    Unlock the power of data with our expert data analysis services. Our skilled analysts extract valuable insights, identify trends, and make data-driven decisions that drive business growth and performance.

                    </p>
                    <button
                    className="btn btn-secondary text-white px-5 py-3 rounded-pill">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-pencil-ruler fa-7x mb-4 text-primary" />
                    <h4 className="mb-3">Graphic Designing
</h4>
                    <p className="mb-4">
                    Elevate your brand and captivate your audience with our creative graphic design services. From marketing campaigns to branding materials, our talented designers bring ideas to life with stunning designs that leave a lasting impression.

                    </p>
                    <button 
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".5s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-keyboard fa-7x mb-4 text-primary" />
                    <h4 className="mb-3"> Data Entry Services
</h4>
                    <p className="mb-4">
                    Efficiently manage vast amounts of information with our reliable data entry services. From inputting data to maintaining databases, we ensure accuracy and reliability, allowing you to streamline processes and focus on core business activities.

                    </p>
                    <button
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".7s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-exchange-alt fa-7x mb-4 text-primary" />
                    <h4 className="mb-3">Returns Process Resource
</h4>
                    <p className="mb-4">
                    We provide comprehensive solutions for managing returns processes efficiently and effectively, ensuring customer satisfaction and maximizing operational efficiency.
                    </p>
                    <button
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".5s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-shopping-cart fa-7x mb-4 text-primary" />
                    <h4 className="mb-3"> Procurement</h4>

                    <p className="mb-4">
                    Our procurement services streamline the purchasing process, helping you source and acquire goods and services efficiently while optimizing costs and ensuring quality.


                    </p>
                    <button
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".5s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-cogs fa-7x mb-4 text-primary" />
                    <h4 className="mb-3"> Product Development

</h4>
                    <p className="mb-4">
                    Partner with us to bring your product ideas to life. Our product development services encompass ideation, prototyping, testing, and launch, helping you innovate and stay ahead in the market.


                    </p>
                    <button
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>

           
          </div>
        </div>
      </div>
{/* Services End */}
</>
  )
}

export default Service;