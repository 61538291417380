import React from 'react'
import{Link,useNavigate} from "react-router-dom"

function Footer({isActive,setActive}) {
  const navigate=useNavigate();

  const handleChange=(e)=>{
    if(e.target.id==="/"){
      navigate("/");
      setActive("home")

    }else{
      const active=e.target.id.slice(1)
      navigate(e.target.id);
      setActive(active)

    }

  }
  return (
    <>
  {/* Footer Start */}
  <div
    className="container-fluid footer bg-dark wow fadeIn"
    data-wow-delay=".3s"
  >
    <div className="container pt-5 pb-4">
      <div className="row g-5">
        <div className="col-lg-3 col-md-6">
          <a href="index.html">
            <h3 className="text-white fw-bold d-block">
              Overseas<span className="text-secondary"> IT Services
</span>{" "}
            </h3>
          </a>
          <p className="mt-4 text-light">
          “Unlock the full potential with tailored IT solutions designed to streamline your business operations, drive growth, and ensure seamless performance.”

          </p>
          
        </div>

        <div className="col-lg-3 col-md-6">
          <a href="#" className="h3 text-secondary">
          Get in Touch
          </a>
          <div className="text-white mt-4 d-flex flex-column contact-link">
            <a
              href="#"
              className="pb-3 text-light border-bottom border-primary"
            >
              <i className="fas fa-map-marker-alt text-secondary me-2" />Mehdipatnam, Hyderabad, INDIA.
            </a>
            <a
              href="#"
              className="py-3 text-light border-bottom border-primary"
            >
              <i className="fas fa-phone-alt text-secondary me-2" />+91 85220 50522
            </a>
            <a
              href="#"
              className="py-3 text-light border-bottom border-primary"
            >
              <i className="fas fa-envelope text-secondary me-2" />{" "}
              info@overseasitservices.com
            </a>
          </div>
          <div className="d-flex hightech-link mt-3">
            <a
              href=""
              className="btn-light nav-fill btn btn-square rounded-circle me-2"
            >
              <i className="fab fa-facebook-f text-primary" />
            </a>
            <a
              href=""
              className="btn-light nav-fill btn btn-square rounded-circle me-2"
            >
              <i className="fab fa-twitter text-primary" />
            </a>
            <a
              href=""
              className="btn-light nav-fill btn btn-square rounded-circle me-2"
            >
              <i className="fab fa-instagram text-primary" />
            </a>
            <a
              href="https://www.linkedin.com/company/overseas-it-services/"
              className="btn-light nav-fill btn btn-square rounded-circle me-0">
              <i className="fab fa-linkedin-in text-primary" />
            </a>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <a href="#" className="h3 text-secondary">
          Quick Links
          </a>
          <div className="mt-4 d-flex flex-column short-link">
            <a id="/" path="home" className="mb-2 text-white m" onClick={handleChange}>
              <i className="fas fa-angle-right text-secondary me-2" />
            Home
            </a>
            <a id="/about" path="about" className="mb-2 text-white m" onClick={handleChange}>
              <i className="fas fa-angle-right text-secondary me-2" />
              About
            </a>
            <a id="/service" path="service" className="mb-2 text-white m" onClick={handleChange}>
              <i className="fas fa-angle-right text-secondary me-2" />
              Services
            </a>
            <a id="/career" path="career" className="mb-2 text-white m" onClick={handleChange}>
              <i className="fas fa-angle-right text-secondary me-2" />
              Career
            </a>
            <a id="/contact" path="contact"className="mb-2 text-white m" onClick={handleChange}>
              <i className="fas fa-angle-right text-secondary me-2" />
              Contact
            </a>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <p className="h3 text-secondary">
          Office Hours

          </p>
          <div className="mt-4 d-flex flex-column help-link">
            <p className="mb-2 text-white">
              <i className="fas fa-angle-right text-secondary me-2" />
              Mon’ - Fri’

            </p>
            <p className="mb-2 text-white">
              <i className="fas fa-angle-right text-secondary me-2" />
              7:30 pm to 5:30 am (IST)
            </p>
            <p className="h3 text-secondary">
            Holidays
          </p>

            <p className="mb-2 text-white">
              <i className="fas fa-angle-right text-secondary me-2" />
              As per USA Calendar

            </p>
            
          </div>
        </div>
       
      </div>
      <hr className="text-light mt-5 mb-4" />
      {/* <div className="row ">
        <div className="col-md-12 ">
          <p className="text-light">
            <a href="#" className="text-secondary">
              <i className="fas fa-copyright text-secondary me-2" />
              
            </a>
             All right reserved. Designed & Maintained by 
            {" "}
          </p>
          


        </div>
        
      </div> */}
      <div className="row">
  <div className="col-md-6 text-center text-md-start">
    <p className="text-light">
      <a href="#" className="text-secondary" style={{marginRight:"11px"}}>
        <i className="fas fa-copyright text-secondary me-2" />
        Overseas IT Services. 
      </a>
        All right reserved.
    </p>
  </div>
  <div className="col-md-6 text-center text-md-end">
    {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
    <span className="text-light">
    Designed & Maintained by 
    <Link to="https://www.sfwebsolutions.in" className="text-secondary"> sfwebsolutions.in
             
             </Link>
    </span>
  </div>
</div>

    </div>
  </div>
  {/* Footer End */}
</>

  )
}

export default Footer